<template lang="pug">
.flex.flex-col.overflow-y-auto.w-full
  p.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.sensor.control") }}
  .flex.mt-3.relative
    .flex.flex-col
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{ $t("notifications.sensor.type") }}
      el-select.mt-2(
        :disabled='!notRole && role !== "EDITOR"',
        v-model='notification.sensor_type',
        clearable,
        :class='{ "input-error": validateOne }'
      )
        el-option(
          v-for='item in sensor_type',
          :key='item.key',
          :label='item.value',
          :value='item'
        )
    .flex.flex-col.ml-3
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{ $t("notifications.sensor.maskByName") }}
      el-input.w-40.mt-2(
        :disabled='!notRole && role !== "EDITOR"',
        v-model='notification.name_mask',
        :class='{ "input-error": validateOne }'
      )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='validateOne') {{ $t("notifications.sensor.warning") }}
  .flex.mt-5.relative
    .flex.flex-col
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold.mb-2 {{ $t("notifications.sensor.meaningFrom") }}
      el-input(
        type='number'
        :disabled='!notRole && role !== "EDITOR"',
        v-model='notification.sensor_notless',
        :class='{ "input-error": validateTwo }'
      )

    .flex.flex-col.ml-3
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold.mb-2 {{ $t("notifications.sensor.meaningTo") }}
      el-input(
        type='number'
        :disabled='!notRole && role !== "EDITOR"',
        v-model='notification.sensor_notmore',
        :class='{ "input-error": validateTwo }'
      )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='validateTwo') {{ $t("notifications.sensor.warningFromTo") }}
  .flex.my-3
    .flex.flex-col.relative
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold.mb-2 {{ $t("notifications.sensor.work") }}
      el-select.w-64(
        :disabled='!notRole && role !== "EDITOR"',
        v-model='notification.in_interval',
        :class='{ "input-error": validateThree }'
      )
        el-option(
          v-for='item in params',
          :key='item.key',
          :label='item.value',
          :value='item.key'
        )
      transition(name='el-zoom-in-top')
        .text-notify.text-xs.top-full.left-0.absolute(v-if='validateThree') {{ $t("notifications.sensor.selectFrame") }}
  .flex.my-3
    .flex.flex-col.relative
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold.mb-2 {{ $t("notifications.sensor.controleGeo") }}
      el-select.w-64(
        :disabled='!notRole && role !== "EDITOR"',
        v-model='selectedGeozonesControle',
        :class='{ "input-error": validateThree }'
      )
        el-option(
          v-for='item in geozonesControl',
          :key='item.key',
          :label='item.value',
          :value='item.key'
        )


  table.w-full
      thead.text-left
        tr
          th.bg-white.top-0.sticky
            span.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.geozones.geozones") }}
          th.bg-white.top-0.sticky.z-10.w-10
            skif-checkbox(
              v-model='isAllChecked',
              :indeterminate='isIndeterminate',
              @change='checkAllUnits($event)',
              :disabled='!filterData.length'
            )
        tr.border-b.border-reallylightblue
          th.sticky.bg-white.z-10(style='top: 32px', colspan='2')
            skif-search.w-64.my-2(
              v-model='filterString',
              :placeholder='$t("search")',
              class='w-1/2',
              @searching='searching'
            )
      tbody
        tr.border-b.border-reallylightblue(
          v-for='geozone in filterData',
          style='height: 43px'
        )
          td
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='geozone.isInArray ? "text-darkblue" : "text-annotationColor"'
            ) {{ geozone.name }}
          td.w-10
            skif-checkbox(
              v-model='geozone.isInArray',
              @change='changeChecked(geozone, $event)'
            )
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: [
    'notification',
    'isSensorTypeOrMask',
    'isIntervalMissing',
    'isSensorMoreLess'
  ],
  data() {
    return {
      params: [
        { key: true, value: this.$t('sensorNotificationTranslater.inFrame') },
        { key: false, value: this.$t('sensorNotificationTranslater.outFrame') }
      ],
      isAllChecked: false,
      isIndeterminate: false,
      filterString: '',
      selectedGeozonesControle: null
    }
  },
  computed: {
    ...mapState({ sensor_type: (state) => state.dictionary.sensor_type }),

    validateOne: {
      get() {
        return this.isSensorTypeOrMask
      },
      set(newValue) {
        this.$emit('updateSensorType', newValue)
      }
    },
    ...mapGetters('login', ['role', 'notRole']),
    ...mapGetters('geozones', {
      geozones: 'geozonesIdNameImei'
    }),
    ...mapGetters('dictionary', {
      geozonesControl: 'geozonesControl'
    }),
    filterData() {
      const filterStringLowercase = this.filterString.toLowerCase()

      const filteredGeozones = this.geozones.filter((geozone) => {
        const geozoneString = JSON.stringify(geozone).toLowerCase()
        return geozoneString.includes(filterStringLowercase)
      })

      const result = filteredGeozones.map((geozone) => {
        const isInArray = this.notification.geozones
          .map((k) => k.id)
          .includes(geozone.id)

        return { ...geozone, isInArray }
      })

      return result
    },
    validateTwo: {
      get() {
        return this.isSensorMoreLess
      },
      set(newValue) {
        this.$emit('updateSensorMoreLess', newValue)
      }
    },
    validateThree: {
      get() {
        return this.isIntervalMissing
      },
      set(newValue) {
        this.$emit('updateInterval', newValue)
      }
    }
  },
  watch: {
    selectedGeozonesControle(newVal) {
      this.$emit('updateGeoControl', newVal)
    },
    'notification.sensor_type': function (val) {
      this.validateOne =
        !Object.keys(val).length && this.notification.name_mask === ''
    },
    'notification.name_mask': function (val) {
      this.validateOne =
        val === '' && !Object.keys(this.notification.sensor_type).length
    },
    'notification.in_interval': function (val) {
      this.validateThree = val === ''
    },
    'notification.sensor_notless': function (val) {
      this.validateTwo =
        +val === '' && Number(this.notification.sensor_notmore) === ''
    },
    'notification.sensor_notmore': function (val) {
      this.validateTwo =
        +val === '' && Number(this.notification.sensor_notless) === ''
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    checkAllUnits($event) {
      // this.notification.geozones = value ? this.geozones : []
      if ($event.target.checked) {
        // eslint-disable-next-line vue/no-mutating-props
        this.notification.geozones = this.filterData
        this.isAllChecked = true
        this.isIndeterminate = false
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.notification.geozones = []
        this.isAllChecked = false
        this.isIndeterminate = false
      }
    },
    changeChecked(geozone, $event) {
      // eslint-disable-next-line vue/no-mutating-props
      this.notification.geozones = $event.target.checked
        ? [...this.notification.geozones, geozone]
        : this.notification.geozones.filter(
            (notifyGeo) => notifyGeo.id !== geozone.id
          )
      const selectedLength = this.notification.geozones.length
      if (selectedLength === this.filterData.length) {
        this.isAllChecked = true
        this.isIndeterminate = false
      } else if (!selectedLength) {
        this.isAllChecked = false
        this.isIndeterminate = false
      } else {
        this.isAllChecked = false
        this.isIndeterminate = true
      }
    }
  },
  mounted() {
    if (this.notification && this.notification.controle_geozones) {
      this.selectedGeozonesControle = this.notification.controle_geozones.key
    }
  }
}
</script>
